import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import {
  msalConfig,
  loginRequest,
  tokenRequest,
  silentRequest,
} from "../config/authConfig";
import {
  updateAccount,
  updateToken,
  updateLoadingStatus,
} from "../store/actions/auth.actions";
import { getProfile } from "../store/actions/employee.actions";

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

// If you support IE, our recommendation is that you sign-in using Redirect flow
const useRedirectFlow = isIE();

const msalApp = new PublicClientApplication(msalConfig);

const AuthHOC = (WrappedComponent) =>
  class AuthProvider extends Component {
    constructor(props) {
      super(props);

      this.state = {
        account: null,
        error: null,
        username: null,
        isAuthenticated: false,
        accessToken: null,
        isLoading: true,
      };
    }

    componentDidMount = () => {
      msalApp.addEventCallback(
        (event) => {
          // set active account after redirect
          if (
            event.eventType === EventType.LOGIN_SUCCESS &&
            event.payload.account
          ) {
            const account = event.payload.account;
            msalApp.setActiveAccount(account);
          }
        },
        (error) => {
          // console.log('error', error);
        }
      );

      //handle auth redired/do all initial setup for msal
      msalApp
        .handleRedirectPromise()
        .then((authResult) => {
          // Check if user signed in
          this.props.updateLoadingStatus(true);
          this.setState({ isLoading: true });
          const account = msalApp.getActiveAccount();
          if (account) {
            this.setState({
              username: account.username,
              account: account,
              isAuthenticated: true,
            });
            msalApp.setActiveAccount(account);
            this.props.updateAccount(account);
            this.acquireToken().then((response) => {
              if (response) {
                this.setState({
                  accessToken: response.accessToken,
                });
                this.props.updateToken(response.accessToken);
                localStorage.setItem(
                  "tokenTimeout",
                  JSON.stringify(response.expiresOn)
                );
                if (this.state.account) {
                  this.props.getProfile(this.state.account.idTokenClaims.oid);
                }
                // this.props.updateLoadingStatus(false);
              }
            });
            this.props.updateLoadingStatus(false);
            this.setState({ isLoading: false });
          } else if (authResult) {
            this.setState({
              username: authResult.account.username,
              account: authResult.account,
              isAuthenticated: true,
            });
            msalApp.setActiveAccount(authResult.account);
            this.props.updateAccount(authResult.account);
            if (this.state.account) {
              this.acquireToken().then((response) => {
                if (response) {
                  this.setState({
                    accessToken: response.accessToken,
                  });
                  this.props.updateToken(response.accessToken);
                  localStorage.setItem(
                    "tokenTimeout",
                    JSON.stringify(response.expiresOn)
                  );
                  if (this.state.account) {
                    this.props.getProfile(this.state.account.idTokenClaims.oid);
                  }
                  // this.props.updateLoadingStatus(false);
                }
              });
            }
            this.props.updateLoadingStatus(false);
            this.setState({ isLoading: false });
          } else {
            msalApp.loginRedirect();
          }
        })
        .catch((err) => {
          // TODO: Handle errors
          // console.log(err);
        });
    };

    getAccounts = () => {
      /**
       * See here for more info on account retrieval:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
       */
      const currentAccounts = msalApp.getAllAccounts();

      if (currentAccounts === null) {
        console.error("No accounts detected!");
        return;
      } else if (currentAccounts.length > 1) {
        console.warn("Multiple accounts detected.");
        // Add choose account code here
        this.setState({
          username: currentAccounts[0].username,
          account: currentAccounts[0],
          isAuthenticated: true,
        });
        this.props.updateAccount(currentAccounts[0]);
      } else if (currentAccounts.length === 1) {
        this.setState({
          username: currentAccounts[0].username,
          account: currentAccounts[0],
          isAuthenticated: true,
        });
        this.props.updateAccount(currentAccounts[0]);
      }
    };

    handleResponse = (response) => {
      console.log(response);
      if (response !== null) {
        this.setState({
          account: response.account,
          username: response.account.username,
          isAuthenticated: true,
          accessToken: response.accessToken,
        });
      } else {
        this.getAccounts();
      }
    };

    acquireToken = async () => {
      /**
       * See here for more info on account retrieval:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
       */
      silentRequest.account = msalApp.getAccountByUsername(this.state.username);
      return msalApp.acquireTokenSilent(silentRequest).catch((error) => {
        console.warn(
          "silent token acquisition fails. acquiring token using interactive method"
        );
        if (error) {
          // fallback to interaction when silent call fails
          tokenRequest.account = msalApp.getAccountByUsername(
            this.state.username
          );
          return msalApp
            .acquireTokenPopup(tokenRequest)
            .then(this.handleResponse)
            .catch((err) => {
              console.error(err);
              this.setState({ error: err.errorMessage });
            });
        } else {
          console.warn(error);
        }
      });
    };

    signIn = async () => {
      return msalApp.loginRedirect(loginRequest);
    };

    signOut = async () => {
      const logoutRequest = {
        account: msalApp.getAccountByUsername(this.state.username),
      };

      return await msalApp.logoutRedirect(logoutRequest);
    };

    render() {
      if (this.state.isLoading) return "Loading";
      return (
        <WrappedComponent
          {...this.props}
          account={this.state.account}
          error={this.state.error}
          isAuthenticated={this.state.isAuthenticated}
          accessToken={this.state.accessToken}
          signIn={() => this.signIn(useRedirectFlow)}
          signOut={() => this.signOut()}
          acquireToken={() => this.acquireToken()}
        />
      );
    }
  };

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  getProfile: (id) => {
    dispatch(getProfile(id));
  },
  updateAccount: (account) => {
    dispatch(updateAccount(account));
  },
  updateToken: (token) => {
    dispatch(updateToken(token));
  },
  updateLoadingStatus: (status) => {
    dispatch(updateLoadingStatus(status));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), AuthHOC);
