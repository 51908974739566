import {
  // applyMiddleware,
  combineReducers,
  // compose,
  configureStore,
} from "@reduxjs/toolkit";
import { routerReducer, routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
// rootReducer from './reducers'

import * as auth from "./reducers/auth.reducer";
import * as employee from "./reducers/employee.reducer";
import * as general from "./reducers/general.reducer";
import * as pronunciation from "./reducers/pronunciation.reducer";

export default function configureAppStore(history) {
  const reducers = {
    auth: auth.reducer,
    employee: employee.reducer,
    general: general.reducer,
    pronunciation: pronunciation.reducer,
  };

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer,
  });

  const middleware = [thunk, routerMiddleware(history)];
  // In development, use the browser's Redux dev tools extension if installed
  // const enhayncers = [];
  //const isDevelopment = process.env.NODE_ENV === "development";
  // if (isDevelopment && typeof window !== "undefined" && window.devToolsExtension) {
  // 	enhancers.push(window.devToolsExtension());
  // }

  // 	const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__() || compose;
  // 	if (process.env.NODE_ENV === 'development') {
  // 		if (typeof devToolsExtension === 'function') {
  // 				enhancers.push(devToolsExtension)
  // 		}
  // }

  // const composedEnhancers = compose(
  // 		applyMiddleware(...middleware),
  // 		...enhancers
  // );

  const store = configureStore({
    reducer: rootReducer,
    middleware: middleware,
    enhancers: [],
  });

  return store;
}
