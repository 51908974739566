const utils = {
  getValidationStateCheckbox: function (
    checked,
    defaultValidationState = null
  ) {
    if (checked === null || checked === undefined)
      return defaultValidationState;
    return "success";
  },

  getValidationStateCollection: function (
    collection,
    min,
    getValidationFunction = null,
    defaultValidationState = null
  ) {
    if (collection === null || collection === undefined)
      return defaultValidationState;
    if (collection.length === 0) return null;
    if (collection.length < min) return "warning";
    if (getValidationFunction) {
      for (let i = 0; i < collection.length; i++) {
        const item = collection[i];
        const itemResult = getValidationFunction(item);
        if (itemResult === "warning" || itemResult === "danger")
          return "warning";
      }
    }
    return "success";
  },

  getValidationStateComments: function (
    comments,
    defaultValidationState = null
  ) {
    if (
      comments === null ||
      comments === undefined ||
      comments.toString().trim() === ""
    )
      return defaultValidationState;
    return true;
  },

  getValidationStateDm: function (dm, defaultValidationState = null) {
    if (dm === null || dm === undefined || dm.toString().trim() === "")
      return defaultValidationState;
    else if (!this.isNumeric(dm)) return "error";
    return "success";
  },

  getValidationStateHours: function (hours, defaultValidationState = null) {
    if (hours === null || hours === undefined || hours === "")
      return defaultValidationState;
    else if (!this.isNumeric(hours)) return "error";
    return "success";
  },

  getValidationStateRevenue: function (revenue, defaultValidationState = null) {
    if (revenue === null || revenue === undefined || revenue === "")
      return defaultValidationState;
    return true;
  },

  getValidationStateSelect: function (select, defaultValidationState = null) {
    if (select === null || select === undefined || select === "")
      return defaultValidationState;
    return "success";
  },

  getValidationStateText: function (text, defaultValidationState = null) {
    if (text === null || text === undefined || text.toString().trim() === "")
      return defaultValidationState;
    return "success";
  },

  hasText: function (text) {
    if (text === null || text === undefined || text.toString().trim() === "")
      return false;
    return true;
  },

  isDefined: function (value) {
    return value !== undefined && value !== null;
  },

  isNumeric: function (value) {
    return !isNaN(value - parseFloat(value));
  },

  isTextComplete: function (value, minLength = 1) {
    return (
      value !== undefined &&
      value !== null &&
      value.toString().length >= minLength
    );
  },

  isEmptyOrZero: function (value) {
    if (!value) return true;
    if (this.isNumeric(value) && parseInt(value, 10) === 0) return true;
    return false;
  },

  setToken: function (token) {
    localStorage.setItem("access_token", token);
  },

  getToken: function () {
    return localStorage.getItem("access_token");
  },

  convertFileToBase64: function (blob, fileName) {
    return new Promise((resolve, reject) => {
      if (blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onload = () =>
          resolve({
            fileName: fileName
              ? `${fileName}.mp3`
              : `${(Math.random() + 1).toString(36).substring(7)}.mp3`,
            base64: reader.result,
          });
        reader.onerror = reject;
      } else {
        resolve({
          fileName: "",
          base64: "",
        });
      }
    });
  },

  getLetters: function (text: string) {
    let letters = "";
    if (text.indexOf(" ") > -1) {
      text.split(" ").map((word) => {
        if (word.length > 1) letters += word.slice(0, 1);
        return "";
      });
    } else {
      letters += text.slice(0, 2);
    }

    if (letters.length > 2) {
      letters = letters.slice(0, 2);
    }
    return letters;
  },

  stringAvatar: function (name: string) {
    return {
      children: this.getLetters(name),
    };
  },
};

export default utils;
