import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  updateAccount,
  updateError,
  updateToken,
  updateIdentity
} from './store/actions/auth.actions';

import App from './App';
import authProvider from './utils/authProvider';
import { getProfile } from './store/actions/employee.actions';


class AppContainer extends Component {
  render() {
    return (
      <App {...this.props} />
    );
  }
}

const mapStateToProps = (state: any) => state;

const mapDispatchToProps = (dispatch : any) => ({
  updateAccount: (account: any) => {    
    dispatch({type: updateAccount(account)})
  },
  updateIdentity: (identity: any) => {    
    dispatch(updateIdentity(identity))
  },
  updateError: (error: any) => {
    dispatch(updateError(error))
  },
  updateToken: (token: any) => {
    dispatch(updateToken(token))
  },
  getProfile: (id: string) => {
    dispatch(getProfile(id));
  }
});

// AppContainer is a container component wrapped by HOC AuthProvider
export default connect(mapStateToProps, mapDispatchToProps)(authProvider(AppContainer));