import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  createPronunciation,
  setNewPronunciation,
} from "../../../../store/actions/pronunciation.actions";
import RecordAudio from "../../../../utils/audioRecorder";
import utils from "../../../../utils/utils";
import Player from "../../../core/player/player";
import { toast } from "react-toastify";

const CreatePronunciation = ({
  createPronunciationAction,
  employeeDetails,
  employeeId,
  audioList,
  setNewPronunctiationAction,
  newPronunciation,
  isPending,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState();
  const [canAccessMicroPhone, setMicroPhoneWarning] = useState(false);

  const onRecordStart = async () => {
    navigator.permissions
      .query({ name: "microphone" })
      .then((status) => {
        if (status && status.state === "granted") {
          setIsRecording(true);
          let record = new RecordAudio({
            bitRate: 128
          });
          setRecorder(record);
          record.start();
        } else {
          setMicroPhoneWarning(true);
        }
      })
      .catch(() => toast.error("Unable start recording!"));
  };

  const onRecordStop = async () => {
    setIsRecording(false);
    const audio = await recorder.stop().getMp3();
    const duration = await getBlobDuration(audio.audioBlob)

    utils
      .convertFileToBase64(audio.audioBlob, getAudioFileName())
      .then((fileResponse) => {        
        const { fileName, base64 } = fileResponse;
        setNewPronunctiationAction({
          ...newPronunciation,
          newAudioURL: audio.audioUrl,
          fileName,
          content: base64,
          audioDuration: duration
        });
      });
  };

  const handlecreatePronunciation = () => {
    createPronunciationAction(newPronunciation, employeeId);
  };

  const getAudioFileName = () => {
    let employeeAudioList =
      audioList &&
      audioList.filter(
        (item) => item.employeeId === employeeDetails.employeeId
      );
    if (employeeAudioList && employeeAudioList.length <= 0)
      return employeeDetails.ntId;

    const lastRecordedAudio = employeeAudioList
      .sort(function (a, b) {
        return a.pronunciationURL > b.pronunciationURL
          ? 1
          : b.pronunciationURL > a.pronunciationURL
          ? -1
          : 0;
      })
      .slice(-1)
      .pop();

    const fileName = lastRecordedAudio.pronunciationURL
      .split("/")
      .slice(-1)
      .pop()
      .replace(".mp3", "");
    const lastChar = fileName.slice(-1);
    if (isNaN(lastChar)) return `${employeeDetails.ntId}1`;
    return `${employeeDetails.ntId}${parseInt(lastChar) + 1}`;
  };

  const getBlobDuration = (blob) => {
    const virtualAudioElement = document.createElement("audio");

    const duration = new Promise((resolve, reject) => {
      virtualAudioElement.addEventListener("loadedmetadata", () => {
        if (virtualAudioElement.duration === Infinity) {
          virtualAudioElement.currentTime = Number.MAX_SAFE_INTEGER;
          virtualAudioElement.ontimeupdate = () => {
            virtualAudioElement.ontimeupdate = null;
            resolve(virtualAudioElement.duration);
            virtualAudioElement.currentTime = 0;
          };
        } else resolve(virtualAudioElement.duration);
      });
      virtualAudioElement.onerror = (event) => reject(event.target.error);
    });

    virtualAudioElement.src =
      typeof blob === "string" || blob instanceof String
        ? blob
        : window.URL.createObjectURL(blob);

    return duration;
  }

  return (
    <>
      <div className="right-box">
        <div className="audio-contaioner">
          <div className="audio">
            <div className="record-button-div">
              <button
                className="record-button"
                onClick={isRecording ? onRecordStop : onRecordStart}
              >
                {isRecording ? "Stop Recording" : "Record your pronunciation"}
              </button>
            </div>
            {isRecording && (
              <div className="record-gif">
                <img
                  src="assets/img/record-animtion.gif"
                  className="record-gif"
                  alt="recording"
                />
              </div>
            )}
            <div>
              <Player
                audioURL={newPronunciation && newPronunciation.newAudioURL}
              />
              <div className="spell-write-div">
                <TextField
                  sx={{ my: 2 }}
                  fullWidth
                  id="fullWidth"
                  placeholder="Please type the Phonetic Pronunciation of your Name"
                  onChange={(e) =>
                    setNewPronunctiationAction({
                      ...newPronunciation,
                      spell: e.target.value,
                    })
                  }
                  value={newPronunciation.spell}
                />
                <Button
                  variant="contained"
                  size="medium"
                  className="save-button"
                  onClick={handlecreatePronunciation}
                  disabled={isPending}
                >
                  {isPending ? "Saving" : "Save"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={canAccessMicroPhone}
        onClose={() => setMicroPhoneWarning(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Permission to use microphone denied."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Microphone access is denied. Please enable the permission in the browser settings and
            refresh the page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMicroPhoneWarning(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ employee, pronunciation }) => {
  const { employeeDetails } = employee;
  const { audioList, newPronunciation, isCreatePending } = pronunciation;
  return {
    audioList,
    employeeDetails,
    newPronunciation,
    isPending: isCreatePending,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    createPronunciationAction: (data, employeeId) =>
      dispatch(createPronunciation(data, employeeId)),
    setNewPronunctiationAction: (data) => dispatch(setNewPronunciation(data)),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(CreatePronunciation);
