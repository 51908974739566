import React, { useEffect } from "react";
import { connect } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  deletePronunciation,
  getPronunciations,
} from "../../../../store/actions/pronunciation.actions";

const PronunciationList = ({
  audioList,
  employeeId,
  deletePronunciationAction,
  getPronunciationsAction,
  handleViewRecord,
  isPending,
}) => {
  const tableHeaders = ["Phonetic Pronunciation", "Modified Date", "Action"];
  const [open, setOpen] = React.useState(false);
  const [selectedPronunciationId, setSelectedPronunciationId] =
    React.useState(null);

  useEffect(() => {
    if (employeeId) {
      getPronunciationsAction(employeeId);
    }
  }, [getPronunciationsAction, employeeId]);

  const handleClickOpen = (id) => {
    setOpen(true);
    setSelectedPronunciationId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deletePronunciationAction(selectedPronunciationId, employeeId);
    handleViewRecord(null);
    setOpen(false);
  };

  console.log(audioList);
  return (
    <div className="left-table">
      <TableContainer
        component={Paper}
        className={isPending ? "main-table whirl" : "main-table"}
      >
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead>
            <TableRow className="header-table">
              {tableHeaders.map((item, index) => (
                <TableCell key={index} align="center">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {audioList && audioList.length <= 0 && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {" "}
                  No records{" "}
                </TableCell>
              </TableRow>
            )}
            {audioList &&
              audioList.map((row) =>
                row.status === "removing" ? (
                  <TableRow
                    key={row.id}
                    colSpan="3"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell colSpan={3} align="center">
                      <Typography>Removing...</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.spell ? row.spell : "Phonetic Name was not entered"}&nbsp;&nbsp;
                      {row.default && (
                        <sup>
                          <Chip
                            label="Default"
                            color="primary"
                            size="small"
                            style={{ height: "20px", fontSize: "10px" }}
                          />
                        </sup>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {moment(row.modifiedDateTime).fromNow()}
                    </TableCell>
                    <TableCell align="center">
                      <PlayCircleIcon
                        className="icons"
                        onClick={() => handleViewRecord(row)}
                      />
                      <DeleteIcon
                        className="icons"
                        onClick={() => {
                          handleClickOpen(row.id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete the pronunciation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            No
          </Button>
          <Button onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ pronunciation }) => {
  const { audioList, isGetPending } = pronunciation;
  return { audioList, isPending: isGetPending };
};

const mapActionsToProps = (dispatch) => {
  return {
    getPronunciationsAction: (employeeId) =>
      dispatch(getPronunciations(employeeId)),
    deletePronunciationAction: (id, employeeId) =>
      dispatch(deletePronunciation(id, employeeId)),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(PronunciationList);
