import React from "react";
import "./style.css";
import "./loading.scss";
import Pronunciation from "./pronunciation";
import Footer from "./footer/footer";
import Header from "./header/header";

const Home = () => {
  return (
    <>
      <Header />
      <Pronunciation />
      <Footer />
    </>
  );
};

export default Home;
