import { toast } from "react-toastify";
import apiServices from "../../services/apiServices";
import {
  CREATE_PRONUNCIATION_ERROR,
  CREATE_PRONUNCIATION_PENDING,
  CREATE_PRONUNCIATION_SUCCESS,
  DELETE_PRONUNCIATION_ERROR,
  DELETE_PRONUNCIATION_PENDING,
  DELETE_PRONUNCIATION_SUCCESS,
  GET_PRONUNCIATION_ERROR,
  GET_PRONUNCIATION_PENDING,
  GET_PRONUNCIATION_SUCCESS,
  MINIMUM_AUDIO_DURATION_IN_SECONDS,
  SET_AUDIO_LIST,
  SET_NEW_PRONUNCIATION,
} from "../constants/pronunciation.constants";

export const setAudioList = (payload) => ({
  type: SET_AUDIO_LIST,
  payload,
});

export const setNewPronunciation = (payload) => ({
  type: SET_NEW_PRONUNCIATION,
  payload,
});

export const createPronunciationPending = () => ({
  type: CREATE_PRONUNCIATION_PENDING,
});

export const createPronunciationSuccess = () => ({
  type: CREATE_PRONUNCIATION_SUCCESS,
});

export const createPronunciationError = () => ({
  type: CREATE_PRONUNCIATION_ERROR,
});

export const getPronunciationPending = () => ({
  type: GET_PRONUNCIATION_PENDING,
});

export const getPronunciationSuccess = () => ({
  type: GET_PRONUNCIATION_SUCCESS,
});

export const getPronunciationError = () => ({
  type: GET_PRONUNCIATION_ERROR,
});

export const deletePronunciationPending = (id) => ({
  type: DELETE_PRONUNCIATION_PENDING,
  payload: id,
});

export const deletePronunciationSuccess = (id) => ({
  type: DELETE_PRONUNCIATION_SUCCESS,
  payload: id,
});

export const deletePronunciationError = (id) => ({
  type: DELETE_PRONUNCIATION_ERROR,
  payload: id,
});

export const createPronunciation =
  (data, employeeId) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { accessToken } = currentState && currentState.auth;

    if (!data.content) {
      toast.error("Please record audio before saving.");
      return;
    }

    if (data.audioDuration < MINIMUM_AUDIO_DURATION_IN_SECONDS) {
      toast.error("No audio was captured.\nPlease try to record your audio again. Make sure to only click “Record Your Pronunciation” once and then click “Stop Recording” once you are done speaking."+       
      "Please contact the Helpdesk if you continue to receive this message.");
      return;
    }

    data = {
      ...data,
      employeeId,
    };

    dispatch(createPronunciationPending());
    apiServices.Pronunciation.createPronunciation(accessToken, data)
      .then(() => {
        toast.success("audio saved!");
        dispatch(getPronunciations(employeeId));
        dispatch(createPronunciationSuccess());
        dispatch(
          setNewPronunciation({
            spell: "",
            isActive: true,
            pronunciationURL: "",
            fileName: "",
            content: "",
            location: "employeepronunciation",
            newAudioURL: "",
          })
        );
      })
      .catch(() => {
        dispatch(createPronunciationError());
      });
  };

export const updatePronunciation =
  (data, employeeId) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { accessToken } = currentState && currentState.auth;

    apiServices.Pronunciation.updatePronunciation(accessToken, data)
      .then(() => {
        toast.success("default audio updated!");
        dispatch(getPronunciations(employeeId));
      })
      .catch(() => {
        toast.error("Unable update audio!");
      });
  };

export const deletePronunciation =
  (id, employeeId) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { accessToken } = currentState && currentState.auth;

    dispatch(deletePronunciationPending(id));
    apiServices.Pronunciation.deletePronunciation(accessToken, id)
      .then(() => {
        dispatch(getPronunciations(employeeId));
        dispatch(deletePronunciationSuccess(id));
        toast.success("audio record deleted successfully!");
      })
      .catch(() => {
        toast.error("Unable delete audio!");
        dispatch(deletePronunciationError(id));
      });
  };

export const getPronunciations =
  (employeeId) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { accessToken } = currentState && currentState.auth;

    dispatch(getPronunciationPending());
    apiServices.Pronunciation.getPronunciations(accessToken, employeeId)
      .then((response) => {
        if (response) {
          dispatch(setAudioList(response.data));
          dispatch(getPronunciationSuccess());
        }
      })
      .catch((err) => {
        toast.error("Unable retrieve audios!");
        dispatch(getPronunciationError());
      });
  };
