import React from "react";
import Home from "./components/home/home";
import { ToastContainer } from "react-toastify";
import { Height } from "@mui/icons-material";


function App() {
  return (
    <div>
      <Home />
      <ToastContainer style={{width:'600px'}}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
