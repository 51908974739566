import axiosClient from "./axiosClient";

const General = {
  getSettings: (accessToken: String) => {
    return axiosClient.get(`api/settings`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};

const Employee = {
  getEmployeeDetails: (accessToken: String, employeeId: String) => {
    return axiosClient.get(`api/employee/${employeeId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getEmployees: (
    accessToken: String,
    searchValue: String,
    email: String = null
  ) => {
    let queryString = "?isActive=true&take=100";
    if (searchValue) queryString = queryString+`&name=${searchValue}`;
    else if (email) queryString = queryString+`&email=${email}`;

    return axiosClient.get(`api/Employee${queryString}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getProfile: (accessToken: String, id: String) => {
    return axiosClient.get(`api/Graph/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};

const Pronunciation = {
  getPronunciations: (accessToken: String, employeeId: String) => {
    return axiosClient.get(
      `api/EmployeePronunciation?employeeId=${employeeId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  },
  createPronunciation: (accessToken: String, data: any) => {
    return axiosClient.post(
      `api/EmployeePronunciation/`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  },
  updatePronunciation: (accessToken: String, data: any) => {
    return axiosClient.put(
      `api/EmployeePronunciation/${data.id}`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  },
  deletePronunciation: (accessToken: String, id: String) => {
    return axiosClient.delete(`api/EmployeePronunciation/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};

const apiService = {
  General,
  Employee,
  Pronunciation,
};

export default apiService;
