import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer">© 2022 Husch Blackwell LLP. All rights reserved</footer>
    </>
  );
};

export default Footer;
