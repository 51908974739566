import { Mp3Encoder } from "lamejs";

class Encoder {
  constructor(config) {
    this.config = {
      sampleRate: 44100,
      bitRate: 128,
    };

    Object.assign(this.config, config);

    this.mp3Encoder = new Mp3Encoder(
      1,
      this.config.sampleRate,
      this.config.bitRate
    );
    this.maxSamples = 1152;
    this.samplesMono = null;
    this.clearBuffer();
  }

  clearBuffer() {
    this.dataBuffer = [];
  }

  appendToBuffer(buffer) {
    this.dataBuffer.push(new Int8Array(buffer));
  }

  floatTo16BitPCM(input, output) {
    for (let i = 0; i < input.length; i++) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }
  }

  convertBuffer(arrayBuffer) {
    const data = new Float32Array(arrayBuffer);
    const out = new Int16Array(arrayBuffer.length);
    this.floatTo16BitPCM(data, out);

    return out;
  }

  encode(arrayBuffer) {
    this.samplesMono = this.convertBuffer(arrayBuffer);

    for (let i = 0; i <= this.samplesMono.length; i += this.maxSamples) {
      const left = this.samplesMono.subarray(i, i + this.maxSamples);
      const mp3buffer = this.mp3Encoder.encodeBuffer(left);
      this.appendToBuffer(mp3buffer);
    }
  }

  finish() {
    this.appendToBuffer(this.mp3Encoder.flush());

    return this.dataBuffer;
  }
}

export default Encoder;
