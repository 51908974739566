import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import utils from "../../../../utils/utils";
import { azureStorage } from "../../../../config/appConfig";

const EmployeeDetails = ({ employeeDetails, azureStorageToken }) => {
  return (
    <>
      {employeeDetails && (
        <>
          <Box
            sx={{ flexGrow: 1, pt: 4 }}
            style={{ justifyContent: "center", display: "flex" }}
          >
            {employeeDetails.photoName && azureStorageToken ? (
              <>
                <Avatar
                  alt={employeeDetails.displayName}
                  src={`${azureStorage.url}/${azureStorage.profilePicFileStore}/${employeeDetails.photoName}${azureStorageToken}&${new Date().getTime()}`}
                  sx={{ width: 120, height: 120 }}
                />
              </>
            ) : (
              employeeDetails.displayName && (
                <>
                  <Avatar
                    {...utils.stringAvatar(employeeDetails.displayName)}
                    sx={{ width: 120, height: 120 }}
                  />
                </>
              )
            )}
          </Box>
          <Box
            sx={{ flexGrow: 1, pt: 2 }}
            style={{ justifyContent: "center", display: "flex" }}
          >
            {employeeDetails.displayName && (
              <>
                <Typography
                  type="title"
                  variant="h6"
                  className="mb-1"
                  color="inherit"
                  display="block"
                >
                  {employeeDetails.displayName}
                </Typography>
              </>
            )}
          </Box>
          {/* <Box
            sx={{ flexGrow: 1 }}
            style={{ justifyContent: "center", display: "flex" }}
          >
            {employeeDetails.employeeId && (
              <Typography variant="caption" display="block" gutterBottom>
                {employeeDetails.employeeId}
              </Typography>
            )}
          </Box> */}
          <Box
            sx={{ flexGrow: 1 }}
            style={{ justifyContent: "center", display: "flex" }}
          >
            {employeeDetails.email && (
              <Typography variant="caption" display="block" gutterBottom>
                {employeeDetails.email}
              </Typography>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default EmployeeDetails;
