import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import EmployeeSearch from "./employeeSearch/employeeSearch";
import ViewPronunciation from "./viewPronunciation/viewPronunciation";
import CreatePronunciation from "./createPronunciation/createPronunciation";
import PronunciationList from "./pronunciationList/pronunciationList";
import { Box, Button, CircularProgress, Grid, Toolbar } from "@mui/material";
import { getSettings } from "../../../store/actions/general.actions";
import { getEmployeeDetails } from "../../../store/actions/employee.actions";
import EmployeeDetails from "./empoyeeDetails/employeeDetails";
import { setNewPronunciation } from "../../../store/actions/pronunciation.actions";

const Index = ({
  getEmployeeDetailsAction,
  getSettingsAction,
  employeeDetails,
  loggedInEmployeeId,
  isPronounciationAdmin,
  isPending,
  isLoading,
  settings,
  setNewPronunctiationAction,
}) => {
  const [canViewSavedRecord, setViewSavedRecord] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  const canShowEmployeeSearch = isPronounciationAdmin;

  const sasToken =
    settings &&
    settings.length > 0 &&
    settings.filter((item) => item.name === "azureStorageKey")[0].value;

  useEffect(() => {
    if (loggedInEmployeeId) {
      getEmployeeDetailsAction(loggedInEmployeeId);
      getSettingsAction();
    }
  }, [getSettingsAction, getEmployeeDetailsAction, loggedInEmployeeId]);

  const setInitialPronunctiation = () => {
    setNewPronunctiationAction({
      spell: "",
      isActive: true,
      pronunciationURL: "",
      fileName: "",
      content: "",
      location: "employeepronunciation",
      newAudioURL: "",
    });
  };
  const handleEmloyeeChange = (newSelectedEmployee) => {
    setInitialPronunctiation();
    setViewSavedRecord(false);
    setSelectedRecord({});
    getEmployeeDetailsAction(newSelectedEmployee.employeeId);
  };

  const handleViewRecord = (data) => {
    if (data == null) {
      setViewSavedRecord(false);
      setSelectedRecord({});
      return;
    }
    setViewSavedRecord(true);
    setTimeout(function () {
      setSelectedRecord(data);
    }, 1000);
  };

  const handleCreateNew = () => {
    setViewSavedRecord(false);
    setInitialPronunctiation();
  };

  return (
    <>
      <Toolbar />
      {isLoading ? (
        <CircularProgress className="main-loader" />
      ) : (
        <Grid container spacing={2} sx={{ pt: 3 }}>
          <Grid item xs={2.5}>
            {canShowEmployeeSearch && (
              <EmployeeSearch onEmployeeChange={handleEmloyeeChange} />
            )}
          </Grid>
          <Grid
            container
            xs={canShowEmployeeSearch ? 9.5 : 12}
            className={isPending ? " whirl" : ""}
          >
            <Grid item xs={5} sx={{p: 1}}>
              {employeeDetails && (
                <EmployeeDetails
                  employeeDetails={employeeDetails}
                  azureStorageToken={sasToken}
                />
              )}
              {canViewSavedRecord ? (
                <Grid item xs={12} sx={{ pt: 3, pl: canShowEmployeeSearch ? 0 : 3 }}>
                  <ViewPronunciation
                    selectedRecord={selectedRecord}
                    employeeId={employeeDetails && employeeDetails.employeeId}
                    handleSelectedRecord={setSelectedRecord}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sx={{ pt: 3 , pl: canShowEmployeeSearch ? 0 : 3}}>
                  <CreatePronunciation
                    employeeId={employeeDetails && employeeDetails.employeeId}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={7}>
              <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                <Grid justifyContent="center">
                  <Grid item xs={12} sx={{ p: 1 }}>
                    <Grid item>
                      <div>
                        <Button
                          variant="contained"
                          size="medium"
                          className="save-button"
                          onClick={() => handleCreateNew()}
                        >
                          Create New
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ p: 1 }}>
                    <PronunciationList
                      handleViewRecord={handleViewRecord}
                      employeeId={employeeDetails && employeeDetails.employeeId}
                    ></PronunciationList>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = ({ auth, employee, general }) => {
  const { isPronounciationAdmin, employeeId, isLoading } = auth;
  const { employeeDetails, isEmployeeDetailsPending } = employee;
  const { settings } = general;
  return {
    isPronounciationAdmin,
    employeeDetails,
    loggedInEmployeeId: employeeId,
    isPending: isEmployeeDetailsPending,
    isLoading,
    settings,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    getEmployeeDetailsAction: (employeeId) =>
      dispatch(getEmployeeDetails(employeeId)),
    getSettingsAction: () => dispatch(getSettings()),
    setNewPronunctiationAction: (data) => dispatch(setNewPronunciation(data)),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(Index);
