import apiServices from "../../services/apiServices";
import format from "../../utils/format";
import {
  updateProfile,
  updateIdentity,
  updateLoadingStatus,
  updateLoggedInEmployeeDetails,
} from "../actions/auth.actions";
import {
  GET_EMPLOYEE_DETAILS_ERROR,
  GET_EMPLOYEE_DETAILS_PENDING,
  GET_EMPLOYEE_DETAILS_SUCCESS,
  GET_EMPLOYEE_LIST_ERROR,
  GET_EMPLOYEE_LIST_PENDING,
  GET_EMPLOYEE_LIST_SUCCESS,
  SET_EMPLOYEE_DETAILS,
  SET_EMPLOYEE_LIST,
} from "../constants/employee.constants";

export const setEmployeeDetails = (payload) => ({
  type: SET_EMPLOYEE_DETAILS,
  payload,
});

export const getEmployeeDetailsPending = (): Action => ({
  type: GET_EMPLOYEE_DETAILS_PENDING,
});

export const getEmployeeDetailsSuccess = (): Action => ({
  type: GET_EMPLOYEE_DETAILS_SUCCESS,
});

export const getEmployeeDetailsError = (): Action => ({
  type: GET_EMPLOYEE_DETAILS_ERROR,
});

export const setEmployeeList = (payload) => ({
  type: SET_EMPLOYEE_LIST,
  payload,
});

export const getEmployeeListSuccess = (): Action => ({
  type: GET_EMPLOYEE_LIST_SUCCESS,
});

export const getEmployeeListError = (): Action => ({
  type: GET_EMPLOYEE_LIST_ERROR,
});

export const getEmployeeListPending = (): Action => ({
  type: GET_EMPLOYEE_LIST_PENDING,
});

export const getEmployeeDetails =
  (employeeId) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { accessToken } = currentState && currentState.auth;
    dispatch(getEmployeeDetailsPending());
    apiServices.Employee.getEmployeeDetails(accessToken, employeeId)
      .then((response) => {
        if (response) dispatch(setEmployeeDetails(response.data));
        dispatch(getEmployeeDetailsSuccess());
      })
      .catch((err) => {
        console.log(err);
        dispatch(getEmployeeDetailsError());
      });
  };

export const getEmployeesForAsyncSelect =
  (searchValue) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { accessToken } = currentState && currentState.auth;
    dispatch(getEmployeeListPending());
    return apiServices.Employee.getEmployees(accessToken, searchValue)
      .then((response) => {
        let _employeelist =
          (response &&
            response.data &&
            format.formatEmployeeList(response.data)) ??
          [];
        dispatch(setEmployeeList(_employeelist));
        dispatch(getEmployeeListSuccess());
        return Promise.resolve(_employeelist);
      })
      .catch((err) => {
        console.log(err);
        dispatch(getEmployeeListError());
        return;
      });
  };

export const getProfile = (id) => (dispatch, getState) => {
  const currentState = getState();
  const { accessToken } = currentState && currentState.auth;

  dispatch(updateLoadingStatus(true));
  return apiServices.Employee.getProfile(accessToken, id)
    .then((response) => {
      if (response) {
        const { data } = response;
        dispatch(updateProfile(data));
        dispatch(updateIdentity(data));
        return apiServices.Employee.getEmployees(
          accessToken,
          null,
          data.userPrincipalName
        ).then((result) => {
          dispatch(updateLoadingStatus(false));
          if (result && result.data && result.data.length > 0) {
            const employee = result.data[0];
            dispatch(updateLoggedInEmployeeDetails(employee));
          }
        });
      }
    })
    .catch();
};
