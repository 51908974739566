import apiServices from "../../services/apiServices";
import { SET_SETTINGS } from "../constants/general.constants";

export const setSettings = (payload) => ({
  type: SET_SETTINGS,
  payload,
});

export const getSettings = () => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { accessToken } = currentState && currentState.auth;
  apiServices.General.getSettings(accessToken)
    .then((response) => {
      if (response) dispatch(setSettings(response.data));
    })
    .catch((err) => {
      console.log(err);
    });
};
