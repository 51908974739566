import * as React from "react";
import { connect } from "react-redux";
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import {
  getEmployeesForAsyncSelect,
  setEmployeeList,
} from "../../../../store/actions/employee.actions";

const drawerWidth = 280;

function EmployeeSearch({
  getEmployeesForAsyncSelectAction,
  onEmployeeChange,
  isPending,
  options,
  setEmployeeListAction,
  myEmployeeId,
}) {
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedEmployee, setSelectedEmployee] = React.useState({});

  React.useEffect(() => {
    (async () => {
      const getData = getEmployeesForAsyncSelectAction();
      getData.then((res) => {
        return res;
      });
    })();
    // eslint-disable-next-line
  }, []);

  const handleSearchChange = () => {
    if (searchValue && searchValue.length < 3) return;
    setEmployeeListAction([]);
    (async () => {
      const getData = getEmployeesForAsyncSelectAction(searchValue);
      getData.then((res) => {
        return res;
      });
    })();
  };

  const handleEmloyeeChange = (emp) => {
    setSelectedEmployee(emp);
    onEmployeeChange(emp);
  };

  const handleMyProfileChange = () => {
    setSelectedEmployee({ employeeId: myEmployeeId });
    onEmployeeChange({ employeeId: myEmployeeId });
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      handleSearchChange();
    }
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List className="employee-search">
          <ListItem>
            <ListItemText
              primary="EMPLOYEES"
              style={{ color: "#1976d2", fontWeight: "700" }}
            />
          </ListItem>
          <ListItem>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Employee"
              inputProps={{ "aria-label": "Search employee" }}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              onKeyPress={handleKeypress}
            />
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearchChange}
            >
              <SearchIcon />
            </IconButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              style={{ padding: "0px" }}
              onClick={handleMyProfileChange}
            >
              <ListItemText
                primary="Switch To My Profile"
                style={{ color: "#1976d2" }}
              />
            </ListItemButton>
          </ListItem>
          {isPending ? (
            <CircularProgress />
          ) : (
            <>
              {options && options.length > 0 ? (
                options.map((option) => (
                  <ListItem
                    key={option.employeeId}
                    className={
                      option.employeeId === selectedEmployee.employeeId
                        ? `active`
                        : ""
                    }
                  >
                    <ListItemButton
                      style={{ padding: "0px" }}
                      onClick={() => handleEmloyeeChange(option)}
                    >
                      <ListItemText primary={option.displayName} />
                    </ListItemButton>
                  </ListItem>
                ))
              ) : (
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  No records
                </div>
              )}
            </>
          )}
          {options && options.length === 100 && (
            <ListItem>
              <ListItemIcon>
                <InfoIcon fontSize="14px"/>
              </ListItemIcon>
              <span style={{ color: "#b28704", fontSize: '12px' }}>
              Limited to 100 recrods. Please include search term to get more specific result.
              </span>
            </ListItem>
          )}
        </List>
        <Divider />
      </Box>
    </Drawer>
  );
}

const mapStateToProps = ({ employee, auth }) => {
  const { isGetEmployeeListPending, employeeList } = employee;
  const { employeeId } = auth;
  return {
    isPending: isGetEmployeeListPending,
    options: employeeList,
    myEmployeeId: employeeId,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    getEmployeesForAsyncSelectAction: (searchValue) =>
      dispatch(getEmployeesForAsyncSelect(searchValue)),
    setEmployeeListAction: (options) => dispatch(setEmployeeList(options)),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(EmployeeSearch);
