import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { updatePronunciation } from "../../../../store/actions/pronunciation.actions";
import Player from "../../../core/player/player";

const ViewPronunciation = ({
  selectedRecord,
  settings,
  employeeId,
  updatePronunciationAction,
  handleSelectedRecord,
}) => {
  const sasToken =
    settings &&
    settings.length > 0 &&
    settings.filter((item) => item.name === "azureStorageKey")[0].value;

  const handleDefaultChange = (event) => {
    const editedData = {
      ...selectedRecord,
      default: event.target.checked,
    };
    handleSelectedRecord(editedData);
    updatePronunciationAction(editedData, employeeId);
  };

  return (
    <>
      <div className="right-box">
        <div className="audio-contaioner">
          <div className="audio">
            <Typography
              type="title"
              variant="h5"
              className="mb-1"
              color="inherit"
            >
              {selectedRecord.spell}
            </Typography>
            <div className="mb-1">
              <Player
                audioURL={`${
                  selectedRecord.pronunciationURL
                }${sasToken}&${new Date().getTime()}`}
              />
            </div>
            {selectedRecord && //  null and undefined check
              Object.keys(selectedRecord).length !== 0 && (
                <div style={{ float: "left" }}>
                  <Typography type="title" variant="p" color="inherit">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedRecord.default}
                            value={selectedRecord.default}
                            onChange={(e) => handleDefaultChange(e)}
                            disabled={selectedRecord.default}
                          />
                        }
                        label="Set as default"
                      />
                    </FormGroup>
                  </Typography>
                </div>
              )}
            <div style={{ float: "right" }}>
              <Typography type="title" variant="p" color="inherit">
                <label>
                  {" "}
                  {selectedRecord.modifiedDateTime
                    ? moment(selectedRecord.modifiedDateTime).fromNow()
                    : ""}
                </label>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ general, employee }) => {
  const { employeeDetails } = employee;
  const { settings } = general;
  return { settings, employeeDetails };
};

const mapActionsToProps = (dispatch) => {
  return {
    updatePronunciationAction: (data, employeeId) =>
      dispatch(updatePronunciation(data, employeeId)),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(ViewPronunciation);
