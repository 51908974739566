import Utils from "./utils";

const format = {
  formatCheckboxInput: function (value) {
    if (value) return true;
    return false;
  },

  formatComments: function (value, maxLength = null) {
    if (value === null || value === undefined) return "";
    if (maxLength !== null && maxLength !== undefined) {
      if (value.length > maxLength)
        return value.substr(0, maxLength - 3) + "...";
    }
    return value;
  },

  formatCommentsInput: function (value) {
    if (value === null) return "";
    return value;
  },

  formatCurrency: function (value) {
    if (!Utils.isNumeric(value)) return "";
    return value.toLocaleString(undefined, {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  },

  formatCurrencyInput: function (value) {
    if (!Utils.isNumeric(value)) return "";
    if (typeof value === "number") return Math.round(value, 2);
    return value;
  },

  formatDate(value) {
    if (value === null) return "";
    if (value === undefined) return "";
    const date = new Date(value);
    return date.toLocaleDateString();
  },

  formatDateTime(value) {
    if (value === null) return "";
    if (value === undefined) return "";
    const date = new Date(value);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  },

  formatHours: function (value) {
    if (!Utils.isNumeric(value)) return "";
    var result = value.toLocaleString(undefined, {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    if (result === "1") result += " hour";
    else result += " hours";
    return result;
  },

  formatHoursInput: function (value) {
    if (!Utils.isNumeric(value)) return "";
    return Math.round(value);
  },

  formatMultipleSelectInput: function (value) {
    if (value === null) return [];
    if (value === undefined) return [];
    const array = value.split(",");
    return array;
  },

  formatMultiselectInput: function (value) {
    if (value === null) return [];
    if (value === undefined) return [];
    if (value === "") return [];
    const array = value.split(",");
    const result = array.map((v) => {
      return {
        value: v,
        label: v,
      };
    });
    return result;
  },

  formatPercentage: function (value, fractionDigits = 1) {
    if (!Utils.isNumeric(value)) return "";
    return (
      value.toLocaleString(undefined, {
        style: "decimal",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }) + "%"
    );
  },

  formatPercentageInput: function (value) {
    if (!Utils.isNumeric(value)) return "";
    return value;
  },

  formatSelectInput: function (value) {
    if (value === null || value === undefined) return "";
    return value;
  },

  formatTextInput: function (value) {
    if (value === null || value === undefined) return "";
    return value;
  },

  formatSbuTextInput: function (value) {
    if (value === null || value === undefined) return "";
    return value + " SBU Leader";
  },

  formatMdTextInput: function (value) {
    if (value === null || value === undefined) return "";
    return value + " Managing Director";
  },

  formatEmployeeList: function (employees) {
    const employeeItems = [];
    employees.forEach((employee) => {
      const { displayName, employeeId } = employee;
      employeeItems.push({
        ...employee,
        label: displayName,
        value: employeeId,
      });
    });
    return employeeItems;
  },
};

export default format;
