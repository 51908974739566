import {
  UPDATE_ACCOUNT,
  UPDATE_ERROR,
  UPDATE_IDENTITY,
  UPDATE_LOADER,
  UPDATE_LOGGEDIN_EMPLOYEE_DETAILS,
  UPDATE_PROFILE,
  UPDATE_TOKEN,
  UPDATE_USER_ID,
} from "../constants/auth.constants";

export const updateAccount = (payload) => ({
  type: UPDATE_ACCOUNT,
  payload,
});

export const updateError = (payload) => ({
  type: UPDATE_ERROR,
  payload,
});

export const updateProfile = (payload) => ({
  type: UPDATE_PROFILE,
  payload,
});

export const updateIdentity = (payload) => ({
  type: UPDATE_IDENTITY,
  payload,
});

export const updateToken = (payload) => ({
  type: UPDATE_TOKEN,
  payload,
});

export const updateLoadingStatus = (payload) => ({
  type: UPDATE_LOADER,
  payload,
});

export const updateUserId = (payload) => ({
  type: UPDATE_USER_ID,
  payload,
});

export const updateLoggedInEmployeeDetails = (payload) => ({
  type: UPDATE_LOGGEDIN_EMPLOYEE_DETAILS,
  payload,
});
