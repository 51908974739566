import Encoder from './encoder';

class RecordAudio {
  constructor(config) {
    this.config = {
      bitRate: 128,
      startRecordingAt: 300,
      deviceId: null,
    };

    this.activeStream = null;
    this.context = null;
    this.microphone = null;
    this.processor = null;
    this.startTime = 0;

    Object.assign(this.config, config);
  }

  addMicrophoneListener(stream) {
    this.activeStream = stream;

    this.timerToStart = setTimeout(() => {
      delete this.timerToStart;
    }, this.config.startRecordingAt);

    this.microphone = this.context.createMediaStreamSource(stream);

    this.processor = this.context.createScriptProcessor(0, 1, 1);

    this.processor.onaudioprocess = (event) => {
      if (this.timerToStart) {
        return;
      }
      this.lameEncoder.encode(event.inputBuffer.getChannelData(0));
    };

    this.microphone.connect(this.processor);
    this.processor.connect(this.context.destination);
  }

  stop() {
    if (this.processor && this.microphone) {
      this.microphone.disconnect();
      this.processor.disconnect();
      if (this.context && this.context.state !== "closed") {
        this.context.close();
      }

      this.processor.onaudioprocess = null;
      this.activeStream.getAudioTracks().forEach((track) => track.stop());
    }

    return this;
  }

  start() {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.context = new AudioContext();
    this.config.sampleRate = this.context.sampleRate;
    this.lameEncoder = new Encoder(this.config);

    const audio = this.config.deviceId
      ? { deviceId: { exact: this.config.deviceId } }
      : true;

    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({ audio })
        .then((stream) => {
          this.addMicrophoneListener(stream);
          resolve(stream);
        })
        .catch(function (err) {
          reject(err);
        });
    });
  }

  getMp3() {
    const finalBuffer = this.lameEncoder.finish();

    return new Promise((resolve, reject) => {
      if (finalBuffer.length === 0) {
        reject(new Error("No buffer to send"));
      } else {
        const audioBlob = new Blob(finalBuffer, { type: "audio/mp3" });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        const play = () => audio.play();
        resolve({ audioBlob, audioUrl, play });
        this.lameEncoder.clearBuffer();
      }
    });
  }
};

export default RecordAudio;