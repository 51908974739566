import { LogLevel } from "@azure/msal-browser";
import { apiConfig } from "./apiConfig";

export const msalConfig = {
  auth: {
    clientId: "#{clientId}#",
    authority:
      "https://login.microsoftonline.com/2e7629c0-a795-4731-8ec5-08ec94449edc",
    redirectUri: `${window.location.origin}`,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
            return;
        }
      },
    },
  },
};

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const loginRequest = {
  scopes: [...apiConfig.resourceScopes],
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
  scopes: [...apiConfig.resourceScopes],
};

// Add here scopes for silent token request
export const silentRequest = {
  scopes: ["openid", "profile", ...apiConfig.resourceScopes],
};
